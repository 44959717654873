import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


function Help() {


  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className='my-4  mx-11 sm:my-12  sm:mx-11  sm:px-11'>
        <h1 className='text-[#2c84ce] text-xl sm:text-3xl font-bold' data-aos='fade-right'>
          Need Help ?
        </h1>

        <p className='text-[#515151] font-light px-11 text-base sm:text-lg my-3 sm:my-7' data-aos='fade-up'>
          Contact our Customer Support that is always ready to help you with any possible questions, problems, or information
        </p>
        <p className='text-[#2c84ce] text-base sm:text-xl' data-aos='fade-left'>
          asim@telecom.com
        </p>
      </div>
    </div>
  );
}

export default Help;
